<template>
  <div class="main-wrapper">
   
        	
          <div class="wrapper">
            
            <div id="form">
            <div class="logo">
		        <img :src="require('../assets/logo.png')" >
            	<!-- <span class="blob3"> <img :src="require('../assets/avatar_4.png')" ></span> -->
		      </div>
            <div class="form">
            
              
              <h4 class="text-center text-white">SET UP WALLET</h4>
                <!-- <button type="submit" class="btn btn-main set-up-wallet" v-if="!isLoading" v-on:click="initImport">
                  <img :src="require('../assets/existing.png')">
                  <span>Import Existing Wallet</span>
                </button> -->
                <button type="button" class="btn btn-second set-up-wallet" v-on:click="generateWallet"  v-if="!successGenerate">
                  <img v-if="!isLoading" :src="require('../assets/newwallet.png')">
                  <span v-if="!isLoading">Create New Wallet</span>

                  <div id="loading" v-if="isLoading">
                    <div class=""> 
                      <span class="btn text-white">Generating your wallet</span>
                      <v-icon name="spinner" pulse scale="2" style="fill:white;"/>

                    </div>
                  </div>
                  </button>
              

              <div class="set-up-wallet text-white" v-if="successGenerate">
                <p class="text-center">Your wallet is successfully created.</p>
                <p class="text-center"> You will be redirected to the Dashboard in a few seconds.</p>
                
              </div>

            </div>
          </div>
          </div>
    
    <ImportWallet />
  </div>
</template>

<script>

  import qs from 'querystring'
  import ImportWallet from '@/components/modal/ImportWallet.vue'
  export default {
    name: 'SetupWallet',
    components: {
      ImportWallet
    },
    data: function() {
      return  {
       isLoading: false,
       successGenerate: false
      }
    },
    computed: {
     
    },
    methods: {
      initImport: function(){
        this.$bvModal.show('importwallet-modal'); 
      },

      checkSession: function() {

        const options = {
          method: 'POST',
          headers: { 'content-type': 'application/x-www-form-urlencoded', 'vmiadmin-authz': localStorage.getItem("tokenId") },
          url: '/ax/sessionCheck',
        };

        this.axios(options)
          .then((response) => {
 
            }).catch((err) => {
                
              console.log(err.response.data)     

              if(err.response.data.msgCode == '00001') {
                window.location.href = "/#/login";
              }  
            })
      },

      generateWallet: function(){
        this.isLoading = true;
        const options = {
          method: 'POST',
          headers: { 'content-type': 'application/x-www-form-urlencoded', 'blox-authz': localStorage.getItem("tokenId") },
          url: '/cx/cryptoaddress/PEG',
        };

        this.axios(options)
          .then((response) => {
              if(response.data.addressValue != undefined && response.data.addressValue != null && response.data.addressValue != '') {
                //

                this.successGenerate = true;
                setTimeout(() => {
                  window.location.href = "/"; 
                },5000)
              } else {
                this.generateWallet();
              }

            }).catch((err) => {
                
              console.log(err.response.data)     

              
            })
       
      }

    },

    beforeMount(){

      this.checkSession();
      
    }
  }
</script>

<style scoped lang="scss">
.blob img{
	z-index: -999;
	display: flex;
	width: 25em;
	position: absolute;
}

 .btn-main{
	  background-image: linear-gradient(135deg,#007bff,#007bff 20%,#007bff 70%,#007bff)!important;
		background-size:0% 100%;
		background-repeat:no-repeat;
		transition:.4s;
		width:100%;
		border-radius: 10px;
		padding:5px;
		border: 1px #007bff solid;
		color:white !important;
  }
  .btn-main:hover{
	  background-size:100% 100%;
    	color:white !important;
  }
  .btn-second{
	  	background-image: linear-gradient(135deg,#007bff,#007bff 20%,#007bff 70%,#007bff)!important;
		background-size:0% 100%;
		background-repeat:no-repeat;
		transition:.4s;
		width:100%;
		border-radius: 10px;
		padding:5px;
		border: 1px #007bff solid;
		color: white !important;
  }
   .btn-second:hover{
	  background-size:100% 100%;
    	color:white !important;
  }
  .btn-second {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; width: 100%;
  }

  .title{
	  color: #333 !important;
	  margin-bottom: 20px;
  }
  .text-muted{
	  color: #333;
	  font-size: 15px;
  }
  

	.logo {
	    display:flex;
	    justify-content: center;
	    align-items: center;
	    margin-bottom: 1em;
	    img{
	      width: 10rem;  
	    }
	    
	  } 
    .btn {
    	font-weight: bold;
    }

	.main-wrapper {
		display: flex;
	    justify-content: center;
	    height: 100vh;
	    flex-direction: column;
	    align-items: center;
	}

	.wrapper {
		padding: 2em;
	    display: block;
	    flex-direction: column;
	    justify-content: space-evenly;
			background: O rgba(255,255,255,0.2);
		backdrop-filter:blur(20px);
		box-shadow:0 0 30px rgba(0,0,0,0.3);
		width: 40em;
		height: 40em;
		    z-index: 2;
		form {
	      color: gray;
	    }
	}


	 .footer {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #5F58A1; 
    bottom: 0.5em;
    width: 100%;
  }
  .set-up-wallet {
    padding: 1em;
    margin-top: 1em;
    cursor: pointer;

    img {
      width: 25px;
      margin-right: 10px;
    }
  }

  .main-container{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 1em;
  }
  .logo {
    display:flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1em;
    img{
      width: 150px;  
    }
    
  }



 	@media only screen and (max-device-width: 480px) {
       /* styles for mobile browsers smaller than 480px; (iPhone) */
	   .blob img{
		display: none !important;
		}
		.logo{
			margin-bottom: 0px !important;
		}
		.email-or-mobile{
			margin-bottom: 0px !important;
		}
		label, .forgot{
			font-size: 10px !important;
		}
	   .wrapper {
			width: 12em !important;
		    z-index: 2;
			h4, .text-muted{
				font-size: 10px;
			}	
		}
		.otp{
			font-size: 14px;
			}
		.otp-wrapper {
			.otp-input{
            width: 30px !important;
			font-size: 0.5em !important;
			}
		}
		.footer {
			font-size: 10px;
		}
    .set-up-wallet p{
      font-size: 10px;
    }
      

       
    }
     @media (min-width: 500px) and (max-width: 580px) {
		.blob img{
		display: none !important;
		}
		.logo{
			margin-bottom: 0px !important;
		}
		.email-or-mobile{
			margin-bottom: 0px !important;
		}
		label, .forgot{
			font-size: 10px !important;
		}
	   .wrapper {
			width: 12em !important;
		    z-index: 2;
			h4, .text-muted{
				font-size: 10px;
			}	
		}
		.otp{
			font-size: 14px;
			}
		.otp-wrapper {
			.otp-input{
            width: 30px !important;
			font-size: 0.5em !important;
			}
		}
		.footer {
			font-size: 10px;
		}


     }

    @media (min-width: 600px) and (max-width: 1024px) {
		.blob img{
		display: none !important;
		}
		
		.logo{
			margin-bottom: 0px !important;
		}
		.email-or-mobile{
			margin-bottom: 0px !important;
		}
		label, .forgot{
			font-size: 10px !important;
		}
	   .wrapper {
			width: 15em !important;
		    z-index: 2;
			h4, .text-muted{
				font-size: 10px;
			}	
		}
		.otp{
			font-size: 17px;
			}
		.otp-wrapper {
			.otp-input{
				width: 40px !important;
				font-size: 0.5em !important;
			}
		}
		.footer {
			font-size: 15px;
		}
       
       
    }
    @media only screen and (min-width: 1280) {
      
    
    }
</style>



