<template>
	<b-modal id="importwallet-modal" hide-footer  hide-header-close no-close-on-backdrop size="md" >

		<template #modal-header> 
      
			<div>
				<v-icon name="chevron-left" @click="close()" />
			</div>
			<h5>
				IMPORT EXISTING WALLET
			</h5> 
			<div></div>

    </template>


		<div  > 
				<div class="send-container" >
					<form v-on:submit.prevent="onImport"> 
	     			<div class="alert alert-danger" v-if="showError"> {{ systemMsg }} </div>
	     			
	   
					  
					   <div class="form-container">
					   	 <label for="privkey" class="text-dark">Enter your Private Key:</label>
						  <input type="text" id="privateKey" name="privateKey" v-model="importWallet.privateKey"  class="form-control" >
						  <span class="error" v-if="errors.privateKey != ''"> {{ errors.privateKey }} </span> 

					  </div>
					  
					  
					  <div class="flex  margin-top">
	    				<button  class="btn btn-main" type="submit">  
	    					<span>Import</span>	
	    				</button>
	    				 
	    			</div>
	    			
	    			
					</form>
				</div> 
		</div>
	</b-modal>
</template>

<script>

import qs from 'querystring'  
export default {
  name: 'ImportWallet',
  data: function() {
      return  {
      	showError: false,
      	importWallet: {
      		privateKey: ''
      	},
      	errors: { 
					privateKey: ""
				},
				systemMsg: ""
      }
    },
  	computed: {
    	init() {
        	return this.$store.state.init;
    	},
    	
   	},
   	methods: {
   		close: function(){
   			this.$bvModal.hide('importwallet-modal'); 
   		},
   		
			onImport: function(){
				let hasError = false;

				
				
				if(this.importWallet.privateKey == '' || this.importWallet.privateKey == undefined || this.importWallet.privateKey == null) {
					this.errors.privateKey = 'Private key is required.';
					hasError = true;
				}
				
				//console.log(hasError);
				if(!hasError) {
					this.showError = false;
					 this.axios.post('/cx/address/import',
						qs.stringify({ 
						    "privateKey": this.importWallet.privateKey
						}))
		            .then((response) => { 
		             
		                console.log(response.data); 
									window.location.href = "/#/";
						
		            }).catch((err) => {
			
			
		                if(err) {
	                  	 this.showError = true;
												
													if(err.response.data.msgCode == "99999") {
														this.systemMsg = "Invalid Private Key."	
													} else {
														if(err.response.data.fieldErrors) {
															this.systemMsg = err.response.data.fieldErrors[0].defaultMessage;
														}
														
													}
													
												} 

		            }).finally ( ()=> {
											setTimeout(() => {
												this.showError = false;
												this.systemMsg = '';
											},3000)
										
										})
				}
				
			},

   	},
}
</script>
 
<style scoped lang="scss">
	
  .send-container { 
	   
	    border-radius: 6px;
	    flex-direction: column;
	    position: relative;
	    background: #FFF; 
	    color: #000;
  }

   .error {
  	color: red;
  }

  button {
  	margin-top:1em;
  }

.btn-main{
	  background-image: linear-gradient(135deg,#007bff,#007bff 20%,#007bff 70%,#007bff)!important;
		background-size:0% 100%;
		background-repeat:no-repeat;
		transition:.4s;
		width:100%;
		border-radius: 10px;
		padding:5px;
		border: 1px #007bff solid;
		color:#007bff !important;
  }
  .btn-main:hover{
	  background-size:100% 100%;
    	color:white !important;
  }
  .btn-second{
	  	background-image: linear-gradient(135deg,#007bff,#007bff 20%,#007bff 70%,#007bff)!important;
		background-size:0% 100%;
		background-repeat:no-repeat;
		transition:.4s;
		width:100%;
		border-radius: 10px;
		padding:5px;
		border: 1px #007bff solid;
		color: white !important;
  }
   .btn-second:hover{
	  background-size:100% 100%;
    	color:white !important;
  }
  .btn-second {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; width: 100%;
  }

	
</style>
